<template>
  <div class="group">
    <textarea
      type="text"
      class="text_small"
      cols="40"
      rows="5"
      @input="onTextInput"
      v-model="this.value"
    />
    <span class="highlight"></span>
    <span class="bar"></span>
    <label class="text_small" v-bind:class="{ textFilled: isFilled }">
      <slot></slot>
    </label>
  </div>
</template>

<script>
import * as _ from "lodash";
export default {
  name: "uiTextArea",
  props: {
    name: { type: String, required: true },
  },
  data() {
    return {
      value: undefined,
      isFilled: false,
    };
  },
  methods: {
    onTextInput() {
      if (!_.isNil(this.value) && this.value !== "") {
        this.isFilled = true;
      }
      this.$emit("textChanged", this.name, this.value);
    },
    clean() {
      this.value = "";
      this.$emit("textChanged", this.name, this.value);
      this.isFilled = false;
    },
  },
};
</script>

<style scoped>
.group {
  position: relative;
}
textarea {
  resize: none;
}
textarea {
  background: none;
  color: white;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
}
textarea:focus {
  outline: none;
}
textarea:focus ~ label {
  top: -14px;
  font-size: 15px;
  color: #ed8202;
}
textarea:focus ~ .bar:before {
  width: 100%;
}
.textFilled {
  top: -14px;
  font-size: 15px;
  color: #ffffff;
}
label {
  color: white;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #ed8202;
  transition: 300ms ease all;
  left: 0%;
}
</style>
