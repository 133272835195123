<template>
  <div class="container">
    <uiAlert ref="alert"></uiAlert>
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '60%', y: '60%' }"
    >
      <div class="top-content-section">
        <h1 class="text_uppercase b_margin_small text_super_big">
          {{ $t("contacts.topPage.p1") }}
        </h1>
        <p class="text_small">{{ $t("contacts.topPage.p2") }}</p>
      </div>
    </uiTopPageSection>
    <section class="contacts-items-dark">
      <div class="max_with contacts-items">
        <div class="contacts-form padding_medium">
          <form>
            <div class="form-desktop-row">
              <ui-text-field
                class="v_margin_big"
                ref="formField1"
                name="firstName"
                @textChanged="textInput"
                :error-message="this.$i18n.t('messages.error1')"
                :error="this.validationErrors.firstName"
              >
                {{ $t("contacts.form.i1") }}
              </ui-text-field>
              <ui-text-field
                class="v_margin_big"
                ref="formField2"
                name="lastName"
                @textChanged="textInput"
                :error-message="this.$i18n.t('messages.error1')"
                :error="this.validationErrors.lastName"
              >
                {{ $t("contacts.form.i2") }}
              </ui-text-field>
            </div>
            <ui-text-field
              class="v_margin_big"
              ref="formField3"
              name="country"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i3") }}
            </ui-text-field>
            <ui-text-field
              class="v_margin_big"
              ref="formField4"
              name="phone"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i4") }}
            </ui-text-field>
            <ui-text-field
              class="v_margin_big"
              ref="formField5"
              name="mail"
              @textChanged="textInput"
              :error-message="this.$i18n.t('messages.error1')"
              :error="this.validationErrors.mail"
            >
              {{ $t("contacts.form.i5") }}
            </ui-text-field>
            <ui-text-area
              class="v_margin_big"
              ref="formField6"
              name="message"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i6") }}
            </ui-text-area>
            <ui-button
              class="button text_small"
              :flat="false"
              bg-color="#FFBE4F"
              border-radius="5px"
              @click.prevent="sendForm"
            >
              {{ $t("contacts.form.b1") }}</ui-button
            >
          </form>
        </div>
        <div class="contacts-region">
          <div>
            <p class="text_italic text_center padding_small">
              {{ $t("contacts.section2.p1") }}
            </p>
          </div>
          <div class="contacts-address">
            <div v-scrollanimate="'bottom'" class="address-item b_margin_small">
              <p class="text_italic">{{ $t("contacts.section2.p2") }}</p>
              <p>
                {{ $t("contacts.section2.p3") }}
              </p>
            </div>
            <div v-scrollanimate="'bottom'" class="address-item b_margin_small">
              <p class="text_italic">{{ $t("contacts.section2.p4") }}</p>
              <p>+1496879766</p>
            </div>
          </div>
          <div>
            <p class="text_italic text_center padding_small">
              {{ $t("contacts.section1.p1") }}
            </p>
          </div>
          <div class="contacts-address">
            <div v-scrollanimate="'bottom'" class="address-item b_margin_small">
              <p class="text_italic">{{ $t("contacts.section1.p2") }}</p>
              <p>
                {{ $t("contacts.section1.p3") }}
              </p>
            </div>
            <div v-scrollanimate="'bottom'" class="address-item b_margin_small">
              <p class="text_italic">{{ $t("contacts.section1.p4") }}</p>
              <p>+380978016980</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/home/home_bg_image.jpg";
import uiTopPageSection from "../components/UI/uiTopPageSection";
import uiTextField from "../components/UI/uiTextField";
import uiButton from "../components/UI/uiButton";
import uiTextArea from "../components/UI/uiTextArea";
import * as _ from "lodash";
import uiAlert from "../components/UI/uiAlert";
export default {
  name: "Contacts",
  components: { uiTopPageSection, uiTextField, uiButton, uiTextArea, uiAlert },
  data() {
    return {
      backgroundImage,
      form: {
        type: "contact",
        firstName: "",
        lastName: "",
        country: "",
        phone: "",
        mail: "",
        message: "",
      },
      validation: {
        notEmpty: ["firstName", "lastName", "mail"],
      },
      validationErrors: {},
    };
  },
  methods: {
    textInput(name, value) {
      if (_.has(this.form, name)) {
        this.form[name] = value;
      }
      if (_.has(this.validationErrors, name)) {
        this.validationErrors[name] = false;
      }
    },
    validateForm() {
      this.validationErrors = {};
      let totalErrors = 0;
      _.forEach(this.validation.notEmpty, (fieldName) => {
        if (_.isNil(this.form[fieldName]) || this.form[fieldName] == "") {
          this.validationErrors[fieldName] = true;
          totalErrors++;
        } else {
          this.validationErrors[fieldName] = false;
        }
      });
      if (totalErrors === 0) {
        return true;
      } else {
        return false;
      }
    },
    sendForm() {
      let validate = this.validateForm();
      if (validate) {
        this.$store.dispatch("sendMail", this.form);
        this.$refs.alert.show(this.$i18n.t("messages.sent"));
        this.clearForm();
      } else {
        this.$refs.alert.show(this.$i18n.t("messages.error2"), true);
      }
    },
    clearForm() {
      for (let i = 1; i <= 6; i++) {
        this.$refs[`formField${i}`].clean();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../vars";
.contacts-items-dark {
  background-color: #0e0e0e;
  color: white;
}
.contacts-region {
  display: none;
  padding: 20px 10px 50px 10px;
  font-size: 16px;
}
.contacts-address {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  div {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
  }
}
.contacts-items {
  display: flex;
  flex-direction: column;
}
.contacts-form {
  .button {
    width: 100%;
    margin: auto;
  }
}
@media (min-width: $small_w) {
  .contacts-region {
    padding: 20px 20px 50px 20px;
  }
}

@media (min-width: $large_w) {
  .contacts-map {
    display: flex;
    flex-direction: row;
    align-items: center;
    .contacts-address {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  .form-desktop-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    * {
      width: 100%;
      margin: auto;
    }
  }
  .contacts-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
